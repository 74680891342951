function Banner() {
    return (
        <section id="hero" className="hero d-flex align-items-center section-bg">
            <div className="container">
                <div className="justify-content-between gy-5">
                    <div lg={8} className="order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start banner-text">
                        <h2 data-aos="fade-up" data-aos-delay="300" className='banner-main-text'> Japanese cuisine <br />with a Modern take</h2>
                    </div>
                    <div lg={8} data-aos="fade-up" data-aos-delay="400" className=" d-flex justify-content-center justify-content-md-center justify-content-lg-start align-items-center align-items-lg-start text-center text-lg-start">
                        <div className="me-1">
                            <a className="btn-order-now" href='https://order.toasttab.com/online/mimik-sushi-doncaster' target="_blank" rel="noreferrer">
                                Order Online
                            </a>
                        </div>
                        <div className="ms-1">
                            <a className="btn-order-now" href='https://order.toasttab.com/egiftcards/mimik-sushi-doncaster?utm_source=undefined&utm_content=online--mimik-sushi-doncaster&utm_medium=toast_sites' target="_blank" rel="noreferrer">
                                Doncaster Giftcards
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner